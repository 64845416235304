<template>
  <div class="all-height all-width">
   <div class="">
      <v-card loader-height="2" :loading="loading" :disabled="loading">
        <v-card-text class="">
          <div>
            <div class="d-flex">
              <div class="d-flex align-center pb-4">
                <span class="display-1">{{allgls.length}}</span>
                <span class="ml-2 line-1">Total<br/>Count</span>
              </div>
              <v-spacer></v-spacer>
              <v-btn class="shadow-off" icon @click="refreshData()"><v-icon>mdi-refresh</v-icon></v-btn>
            </div>
            
            <div class="d-flex">
              <div class="pb-4">
                <div>Below is the list of all the GL's in this application.</div>
                <div>You can define the order in which the GL's have to be displayed here.</div>
              </div>
              <v-spacer></v-spacer>
              <div class="text-right" v-if="pendingchange">
                <div><v-btn class="shadow-off" small color="primary" @click="saveData()"><v-icon class="mr-1">mdi-content-save</v-icon>Save changes</v-btn></div>
                <div class="error--text">Few changes are yet to be saved</div>
              </div>
              <!-- <div class="text-right ml-2" v-if="unapprovedrequest.length > 0">
                <v-tooltip bottom content-class="tooltip-bottom">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="mx-0 px-0" icon small v-bind="attrs" v-on="on" @click.stop="pendingdialog=true">
                      <v-icon>mdi-timer-sand</v-icon>
                    </v-btn>
                  </template>
                  <span>View pending approval</span>
                </v-tooltip>
              </div> -->
            </div>


            <div v-if="allgls.length > 0" class="">
              <div v-for="(v, k) in getParentList" :key="k">
                <div class="cursor-pointer hoverable">
                  <div class="d-flex align-center py-1 px-2" @click="expandColapseList(v.code)">
                    <!-- <v-checkbox label="" dense class="my-0 pt-0 mx-2" @click.stop hide-details v-model="selectedgls" :value="v.code"></v-checkbox> -->
                    <span class="font-weight-bold">{{ v.code || "NA" }}</span>
                    <v-icon>mdi-circle-small</v-icon>
                    <span class="font-weight-bold">{{ v.name }}</span>
                    <span v-if="(gllist[v.code] || []).length > 0 && !v.isnewgl">
                      <v-icon class="ml-2" v-if="expandedgls.indexOf(v.code) > -1 ">mdi-chevron-down</v-icon>
                      <v-icon class="ml-2" v-else>mdi-chevron-right</v-icon>
                    </span>
                    <v-spacer></v-spacer>
                    <v-chip v-if="v.isnewgl" small label color="info"
                      class="white--text px-2">New</v-chip>
                    <v-chip v-if="(gllist[v.code] || []).length > 0 && !v.isnewgl" small label color="caccounting" class="white--text px-2 mr-1 ml-2"><v-chip x-small color="white" class="mr-2 px-2">{{ (gllist[v.code] || []).length }}</v-chip>GL's</v-chip>
                    
                    <v-tooltip bottom content-class="tooltip-bottom" v-if="k!== 0">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn class="mx-0 px-0" icon small v-bind="attrs" v-on="on" @click.stop="interchangeposition(k, 'primary', 'up')">
                          <v-icon>mdi-arrow-up</v-icon>
                        </v-btn>
                      </template>
                      <span>Move up</span>
                    </v-tooltip>
                    <v-tooltip bottom content-class="tooltip-bottom" v-if="k!== gllist['primary'].length -1">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn class="mx-0 px-0" icon small v-bind="attrs" v-on="on" @click.stop="interchangeposition(k, 'primary', 'down')">
                          <v-icon>mdi-arrow-down</v-icon>
                        </v-btn>
                      </template>
                      <span>Move down</span>
                    </v-tooltip>
                    <v-tooltip bottom content-class="tooltip-bottom">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn class="mx-0 px-0" icon small v-bind="attrs" v-on="on" @click.stop="changeParentDialog(v)">
                          <v-icon>mdi-format-list-bulleted</v-icon>
                        </v-btn>
                      </template>
                      <span>Grouping</span>
                    </v-tooltip>
                  </div>
                  <div v-if="expandedgls.indexOf(v.code) > -1">
                    <div v-for="(sv, sk) in gllist[v.code] || []" :key="sk" class="ml-4">
                      <div class="cursor-pointer hoverable px-2">
                        <div class="d-flex align-center">
                          <!-- <v-checkbox label="" dense class="my-0 pt-0 mx-2" hide-details v-model="selectedgls" :value="sv.code"></v-checkbox> -->
                          <span class="font-weight-bold">{{ sv.code }}</span>
                          <v-icon>mdi-circle-small</v-icon>
                          <span>{{ sv.name }}</span>
                          <v-spacer/>
                          <v-tooltip bottom content-class="tooltip-bottom" v-if="sk!== 0">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn class="mx-0 px-0" icon small v-bind="attrs" v-on="on" @click.stop="interchangeposition(sk, v.code, 'up')">
                                <v-icon>mdi-arrow-up</v-icon>
                              </v-btn>
                            </template>
                            <span>Move up</span>
                          </v-tooltip>
                          <v-tooltip bottom content-class="tooltip-bottom" v-if="sk!== gllist[v.code].length -1">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn class="mx-0 px-0" icon small v-bind="attrs" v-on="on" @click.stop="interchangeposition(sk, v.code, 'down')">
                                <v-icon>mdi-arrow-down</v-icon>
                              </v-btn>
                            </template>
                            <span>Move down</span>
                          </v-tooltip>
                          <v-tooltip bottom content-class="tooltip-bottom">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn class="mx-0 px-0" icon small v-bind="attrs" v-on="on" @click.stop="changeParentDialog(sv)">
                                <v-icon>mdi-format-list-bulleted</v-icon>
                              </v-btn>
                            </template>
                            <span>Grouping</span>
                          </v-tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <v-divider></v-divider>
              </div>
              <!-- <div class="" v-for="(v,k) in data" :key="k">
                <div class="d-flex align-center pa-2 hoverable">
                  <span class="mr-2">{{ k+1 }}.</span>
                  <div class="subtitle-2">{{v.name}}</div>
                  <v-spacer></v-spacer>
                  <v-chip small label color="info" class="white--text px-2 mr-2"><v-chip x-small color="white" class="mr-2 px-2">{{ v.rucount }}</v-chip>RU's</v-chip>
                  <v-chip small label color="success" class="white--text px-2 mr-2"><v-chip x-small color="white" class="mr-2 px-2">{{ (v.gls || []).length }}</v-chip>GL's selected</v-chip>
                  <div class="text-center">
                    <v-tooltip bottom content-class="tooltip-bottom">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn @click="editData(v)" v-bind="attrs" v-on="on" small icon><v-icon>mdi-pencil</v-icon></v-btn>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                  </div>
                </div>
                <v-divider></v-divider>
              </div> -->
            </div>
            <div v-else class="title grey--text pa-5 text-center">
              No Data Found
            </div>
          </div>

          <!-- <lb-dialog v-model="pendingdialog" heading="Verify & Upload Trial Balance" width="800">
            <template v-slot:body>
              <div v-if="unapprovedrequest.length > 0">
                <div class="text-right mb-4">
                  <span class="border-left-info px-2 py-1 border-on">Changed GLs</span>
                </div>
                <div v-for="(v, k) in pendinggllist['parent']" :key="k">
                  <div class="hoverable">
                    <div :class="`d-flex align-center py-1 px-2 ${(v.order !== v.neworder)?'border-left-info':'border-left-transparent'}`">
                      <span class="font-weight-bold">{{ v.code }}</span>
                      <v-icon>mdi-circle-small</v-icon>
                      <span class="font-weight-bold">{{ v.name }}</span>
                      <v-spacer></v-spacer>
                      <v-chip v-if="(pendinggllist[v.code] || []).length > 0" small label color="caccounting" class="white--text px-2 mr-1"><v-chip x-small color="white" class="mr-2 px-2">{{ (pendinggllist[v.code] || []).length }}</v-chip>GL's</v-chip>
                    </div>
                    <div>
                      <div v-for="(sv, sk) in pendinggllist[v.code] || []" :key="sk" class="ml-4">
                        <div class="hoverable px-2">
                          <div class="d-flex align-center">
                            <span class="font-weight-bold">{{ sv.code }}</span>
                            <v-icon>mdi-circle-small</v-icon>
                            <span>{{ sv.name }}</span>
                            <v-spacer/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <v-divider></v-divider>
                </div>
              </div>
              <div v-else class="grey--text py-4 text-center body-1">No pending approval found </div>
            </template>
          </lb-dialog> -->

          <lb-dialog v-model="glgroupingdialog" :loading="glgroupingloading" heading="Group GL" width="500">
            <template v-slot:body>
              <div>
                <div class="mb-4">
                  <div class="subtitle-1 mb-2">{{glgroupingselected.code}} - {{glgroupingselected.name}}</div>
                  <div class="">Select whether this GL should become the Parent(primary) or it should form part of an existing parent GL</div>
                </div>
                <lb-dropdown
                  hidedetails
                  label="Select Parent"
                  v-model="selectedparent"
                  :items="getParentListForChange()"
                  itemtext="text"
                  itemvalue="value"
                />
              </div>
            </template>
            <template v-slot:actions>
              <v-spacer></v-spacer>
              <v-btn v-if="selectedparent !== glgroupingselected.parent" @click="changeParent()" color="success" small class="ml-2">Change</v-btn>
            </template>
          </lb-dialog>

        </v-card-text>
      </v-card>

    </div>
  </div>
</template>

<script>
export default {
  name: 'reportingunits',
  data: function(){
    return {
      loading: false,
      allgls: [],
      data: [],
      expandedgls: [],
      gllist: {},
      selectedgls: [],
      unapprovedrequest: [],
      pendingchange: false,
      // pendingdialog: false,
      // pendinggllist: {},
      glgroupingselected: {},
      glgroupingdialog: false,
      glgroupingloading: false,
      selectedparent: null,
      isNewList:false,
    }
  },
  activated() {
    this.refreshData();
  },
  mounted() {
    this.refreshData();
  },
  methods: {
    refreshData() {
      this.getData();
    },
    getData() {
      this.loading = true;
      this.axios.post("/v2/varianceanalysis/gllist/get").then(dt => {
        if(dt.data.status === "success") {
          this.data = dt.data.data;
          this.setDisplayList();
          this.pendingchange = false;
          // return this.axios.post("/v2/varianceanalysis/gllist/getpendingorderrequest");
        }
        else throw new Error ("Error reading data");
      // }).then(dt => {
      //   if(dt.data.status === "success") {
      //     if(dt.data.data.length > 0) {
      //       this.unapprovedrequest = dt.data.data;
      //       this.pendinggllist = {};
      //       this.pendinggllist["parent"] = [];
      //       for (const i of (this.unapprovedrequest || [])) {
      //         i.neworder = i.neworder || 0;
      //         if(i.parent) {
      //           this.pendinggllist[i.parent] = this.pendinggllist[i.parent] || [];
      //           this.pendinggllist[i.parent].push(i)
      //         }else {
      //           this.pendinggllist["parent"] = this.pendinggllist["parent"] || [];
      //           this.pendinggllist["parent"].push(i)
      //         }
      //       }
      //       this.pendinggllist["parent"] = this.reorder(this.pendinggllist["parent"] || [], 'neworder');
      //       for (const i of this.pendinggllist['parent']) {
      //         this.pendinggllist[i.code] = this.reorder(this.pendinggllist[i.code] || [], 'neworder');
      //       }

      //     }
      //   }
      //   else throw new Error ("Error reading pending request data");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    changeParentDialog(v){
      this.glgroupingselected=v;
      console.log(this.glgroupingselected);
      this.selectedparent = v.parent;
      this.glgroupingdialog = true
    },
    changeParent(){
      this.glgroupingloading = true;
      this.data[this.glgroupingselected.__key].parent = this.selectedparent;
      this.pendingchange = true;
      this.setDisplayList();
      this.glgroupingloading = false;
      this.glgroupingdialog = false;
    },
    setDisplayList(){
      this.gllist = {};
      let allgllist = []
      let key = -1;
      for (const i of (this.data || [])) {
        key++;
        i.__key = key;
        allgllist.push(i.code);
        i['isnewgl'] = false;
        // Check if the record was created within the last 60 days
        if ((new Date() - new Date(i.created_at)) / (1000 * 60 * 60 * 24) <= 60) {
          // this.gllist['new'] = this.gllist['new'] || [];

          // Create a shallow copy of 'i' to avoid reference issues
          // let newItem = { ...i, isnewgl: true };  // Set isnewgl true for this new copy
          i['isnewgl'] = true
          // this.gllist['new'].push(newItem);  // Push the cloned item
          // console.log('new gl ' + i.code);
        }
        if (i.parent) {
          this.gllist[i.parent] = this.gllist[i.parent] || [];
          this.gllist[i.parent].push(i);  // i['isnewgl'] will be false here
        } else {
          this.gllist["NA"] = this.gllist["NA"] || [];
          this.gllist["NA"].push(i);
        }

        
      }
      this.gllist["primary"] = this.reorder(this.gllist["primary"] || [], 'order');
      this.gllist["NA"] = this.reorder(this.gllist["NA"] || [], 'order');
      // this.gllist["new"] = this.reorder(this.gllist["new"] || [], 'order');
      for (const i of this.gllist['primary']) {
        this.gllist[i.code] = this.reorder(this.gllist[i.code] || [], 'order');
      }
      // if((this.data  || []).length === (this.gllist?.new || []).length){
      //   this.isNewList = true;
      // }
      this.allgls = allgllist;
    },
    getParentListForChange() {
      let dt = [{value: "NA", text: "Ungrouped: Other sub-schedules and further Analysis Accounts"}, {value: "primary", text: "Primary"}];
      // console.log(this.gllist["primary"] || []);
      for (const i of this.gllist["primary"] || []) {
        dt.push({value: i.code, text: i.name});
      }
      return dt;
    },
    saveData() {
      this.loading = true;
      let counter = 1;
      let dt = [];
      for (const i of this.gllist['primary']) {
        dt.push({_id: i._id, order: counter++, parent: "primary"});
        for (const j of (this.gllist[i.code] || [])) {
          dt.push({_id: j._id, order: counter++, parent: i.code});
        }
      }
      let successflag = false;
      this.axios.post("/v2/varianceanalysis/gllist/saveorder", {data: dt}).then(dt => {
        if(dt.data.status === "success"){
          this.$store.commit("sbSuccess", "GL order saved");
          successflag = true;
        }else throw dt.data.message || "error updating gl order";
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        if(successflag) this.refreshData();
        this.loading = false;
      })
    },
    interchangeposition(k, type, action){
      if(action === 'down'){
        if(type === 'primary'){
          if(k < ((this.gllist['primary'] || []).length -1)){
            let temp = this.gllist['primary'][k];
            this.gllist['primary'][k] = this.gllist['primary'][k+1];
            this.gllist['primary'][k+1] = temp;
          }
        }else{
          if(k < ((this.gllist[type] || []).length -1)){
            let temp = this.gllist[type][k];
            this.gllist[type][k] = this.gllist[type][k+1];
            this.gllist[type][k+1] = temp;
          }
        }
      }
      else if(action === 'up'){
        if(type === 'primary'){
          if(k > 0){
            let temp = this.gllist['primary'][k];
            this.gllist['primary'][k] = this.gllist['primary'][k-1];
            this.gllist['primary'][k-1] = temp;
          }
        }else{
          if(k > 0){
            let temp = this.gllist[type][k];
            this.gllist[type][k] = this.gllist[type][k-1];
            this.gllist[type][k-1] = temp;
          }
        }
      }
      this.pendingchange = true;
      let t = this.gllist;
      this.gllist = {};
      this.gllist = t;

    },
    selectAll() {
      this.selectedgls = this.allgls;
    },
    unSelectAll() {
      this.selectedgls = [];
    },
    expandColapseList(v) {
      if(this.expandedgls.indexOf(v) > -1) this.expandedgls.splice(this.expandedgls.indexOf(v), 1)
      else this.expandedgls.push(v);
    },
    reorder(dt, key){
      return dt.sort(function(a,b){return a[key] - b[key];});
    }
  },
  computed: {
    getParentList: {
      get() {
        let dt = [...this.gllist['primary'] || []];
        // if(!this.isNewList){
        //   dt = [...dt, ...(this.gllist['new'] || [])];
        // }
        dt.push({code: "NA", name: "Ungrouped: Other sub-schedules and further Analysis Accounts"})
        return dt;
      },
      cache: false
    },
  }

}
</script>

<style scoped>
transition-list * {
  transition: all 0.5s ease-in-out;
}
</style>
